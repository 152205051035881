/* Copyright(c) 2016, iovation, inc. All rights reserved. */

window.io_global_object_name = "IGLOO";
window.IGLOO = window.IGLOO || {
    "enable_flash" : false,
    "bbout_element_id" : "ioBlackBox",
    "loader" : {
        "version" : "general5",
        "subkey" : "??",
        "uri_hook" : "/iojs"
    }
};